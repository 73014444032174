<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>User Group</label>
          <select class="form-control" v-model="price.user_group_id">
            <template v-for="(role, i) in roles">
              <option :value="role.id" :key="`role-${i}`">{{
                role.name
              }}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Lower Bound</label>
          <input
            type="number"
            required
            v-model="price.lower_bound"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Upper Bound</label>
          <input
            type="number"
            required
            v-model="price.upper_bound"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Charge Type</label>
          <select v-model="price.charge_type" class="form-control">
            <option value="" disabled selected>Please select</option>
            <option value="fixed">Fixed</option>
            <option value="percent">Percentage</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cost</label>
          <input
            type="text"
            required
            v-model="price.cost"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Tender Period</label>
          <select v-model="price.timeline" class="form-control">
            <option value="" disabled selected>Please select</option>
            <template v-for="group in Math.ceil(39 / 3)">
              <template v-if="group <= 14">
                <option :value="group * 3" :key="group">{{ getGroupLabel(group) }}</option>
              </template>
              <optgroup v-else :label="getGroupLabel(group)" :key="group">
                <option v-for="month in 3" :value="((group - 1) * 3) + month" :key="((group - 1) * 3) + month">
                  {{ getTimelineText(((group - 1) * 3) + month) }}
                </option>
              </optgroup>
            </template>
          </select>
        </div>
      </div>

    
      <div class="col-md-12 text-right">
        <button class="btn btn-secondary">
          <i class="lni-save mr-2"></i> Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },
  computed: {
  getTimelineText() {
    return function (month) {
      if (month === 1) {
        return '1 month';
      } else {
        return `${month} months`;
      }

      
    };

  }
},

  data() {
    return {
      price: {
        timeline: '',
      },
      error: "",
      roles: [],
      timeline:[]
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/dashboard/roles").then((response) => {
        this.roles = response.data.roles;
      });
    },
    getGroupLabel(group) {
        return group * 3 + ' months';
      },
    submit() {
      this.$axios
        .post("/api/v1/dashboard/performancebond-pricing", this.price)
        .then(() => {
          this.$emit('close')
        })
        .catch((error) => {
          this.$http(error.response);
        });
    },
  },

};
</script>
