<template>
  <div>
    <page-title :title="`Performancebond Pricing`">
      <template slot="button">
        <a href="#" @click.prevent="show_create = true" style="color:white !important" class="btn btn-primary btn-sm float-right" ><i class="lni-plus mr-2"></i> Add Performancebond Pricing</a>
      </template>
    </page-title>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>User Group</th>
                  <th>Lower Bound</th>
                  <th>Upper Bound</th>
                  <th>Cost</th>
                  <th>Tender Period (months)</th>
                  <th>Charge Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(price, i) in pricing" :key="`price-${i}`">
                  <td>{{ price.user_group }}</td>
                  <td>{{ $number.format(price.lower_bound) }}</td>
                  <td>{{ $number.format(price.upper_bound) }}</td>
                  <td>{{ $number.format(price.cost) }}</td>
                  <td>{{ price.timeline }} </td>
                  <td>{{ price.charge_type }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm"
                      @click.prevent="edit(price)"
                    >
                      <i class="lni-pencil mr-2"></i> Edit
                    </button>
                    <a
                      class="btn btn-danger btn-sm ml-1"
                      href="#"
                      @click.prevent="destroy(price)"
                      ><i class="lni-trash mr-2"></i> Delete</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <modal :showModal="showModal" @close="showModal = false">
              <template v-slot:header>
                <h5>Edit pricing for {{ edit_price.user_group_id }}</h5>
              </template>
              <form @submit.prevent="update">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Lower Bound</label>
                      <input type="number" class="form-control" v-model="edit_price.lower_bound" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Upper Bound</label>
                      <input type="number" class="form-control" v-model="edit_price.upper_bound" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Charge Type</label>
                      <select class="form-control" v-model="edit_price.charge_type">
                        <option value="fixed">Fixed</option>
                        <option value="percent">Percent</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Cost</label>
                      <input type="text" class="form-control" v-model="edit_price.cost" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Tender Period</label>
                      <select v-model="edit_price.timeline" class="form-control">  
                        <option value="" disabled selected>Please select</option>
                        <option v-for="month in 39" :value="`${month}`" :key="month">{{ getTimelineText(month) }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group text-right">
                      <button class="btn btn-secondary"><i class="lni-save"></i> Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </modal>
          </div>
        </div>
      </div>
    </div>
    <modal :showModal="show_create" @close="show_create = false">
      <template slot="header">
        <h3>Add Performancebond Pricing</h3>
      </template>
      <create @close="show_create = false; fetch()" />
    </modal>
  </div>
</template>

<script>
import $ from 'jquery'
import Create from './Create'

export default {
  components: {
    Create
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      pricing: [],
      edit_price: {},
      showModal: false,
      show_create: false
    };
  },
  computed: {
  getTimelineText() {
    return function (month) {
      if (month === 1) {
        return '1 month';
      } else {
        return `${month} months`;
      }

      
    };

  }
},
  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get("/api/v1/dashboard/performancebond-pricing").then(response => {
        this.pricing = response.data;
        setTimeout(() => {
          $('table').DataTable()
          this.$loader.stop()
        }, 1000)
      });
    },

    edit(price) {
      this.edit_price = price;
      this.showModal = true;
    },

    update() {
      this.$axios
        .put(
          "/api/v1/dashboard/performancebond-pricing/" + this.edit_price.id,
          this.edit_price
        )
        .then(() => {
          this.showModal = false;
          this.fetch();
        });
    },

    destroy(price) {
      this.$axios
        .delete(
          `/api/v1/dashboard/performancebond-pricing/${price.id}`
        )
        .then(() => {
          this.fetch();
          this.$notify({
            group: 'foo',
            title: 'Success',
            text: 'The pricing has been deleted',
            type: 'success'
          });
        });
    }
  }
};
</script>